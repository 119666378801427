<template>
  <v-card
    class="mx-auto"
    width="100%"
  >
    <v-card-title>
      <v-app-bar
        flat
        class="white pa-0"
        elevate-on-scroll
        dense
        data-cy="single_scan_title"
      >
        <v-toolbar-title
          data-cy="singlescan_title"
        >
          {{ $t('screen.single.title') }}
        </v-toolbar-title>
        <v-spacer />
      </v-app-bar>
    </v-card-title>
    <v-card-text>
      <v-container
        fill-height
        fluid
      >
        <loading
          :active.sync="loading"
          :is-full-page="false"
        />
        <v-row>
          <v-col cols="6">
            <v-card-subtitle>{{ $t('screen.single.subtitle.criteria') }}</v-card-subtitle>
            <v-card-text>
              <v-text-field
                :label="$t('screen.single.name.label')"
                autocomplete="off"
                v-model="form.name"
                :error="$v.form.name.$error"
                :error-messages="$v.form.name.$error ? (
                  !$v.form.name.required ? $t('validate.required', { field: $t('screen.single.name.label') }) : (
                    !$v.form.name.minLenght ? $t('validate.minLength', { field: $t('screen.single.name.label'), length: 2 }) : ''
                  )
                ) : ''"
                data-cy="name_textfield"
              />
              <CountrySelector
                :label="$t('screen.single.nationality.label')"
                v-model="form.nationality"
                data-cy="country_selector"
              />
              <v-text-field
                :label="$t('screen.single.dob.label')"
                v-model="form.dob"
                data-cy="dob"
              />
              <v-btn
                color="primary"
                @click="onSubmit"
                :disabled="loading"
                data-cy="single_scan_btn"
              >
                <v-icon left>
                  mdi-magnify
                </v-icon>
                {{ loading ? $t('screen.single.buttons.loading') : $t('screen.single.buttons.scan') }}
              </v-btn>
            </v-card-text>
          </v-col>
          <v-col cols="6">
            <v-card-subtitle>{{ $t('screen.single.subtitle.results') }}</v-card-subtitle>
            <v-card-text
              v-if="!loading && results"
              ref="hit_results"
            >
              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                    <tr
                      data-cy="result_name_row"
                    >
                      <td>{{ $t('screen.single.name.label') }}</td>
                      <td>{{ results.name || 'N/A' }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t('screen.single.nationality.label') }}</td>
                      <td>{{ results.nationality || 'N/A' }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t('screen.single.dob.label') }}</td>
                      <td>{{ results.dob || 'N/A' }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t('screen.single.hit.label') }}</td>
                      <td :class="results.hit ? 'red--text' : 'green--text'">
                        {{ results.hit ? $t('screen.single.hit.options.yes') : $t('screen.single.hit.options.no') }}
                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t('alert.details.form.service_id.label') }}</td>
                      <td>{{ results.service_id }}</td>
                    </tr>
                    <tr v-if="results.hit">
                      <td>{{ $t('screen.single.buttons.view_details') }}</td>
                      <td class="pa-2">
                        <!-- <p>{{ results.service_encrypt }}</p> -->
                        <v-btn
                          :href="results.link"
                          target="_blank"
                          data-cy="view_screening_detail_btn"
                        >
                          <v-icon left>
                            mdi-open-in-new
                          </v-icon>
                          {{ $t('screen.single.buttons.view_details') }}
                        </v-btn>
                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t('screen.single.risk_level.label') }}</td>
                      <td>{{ results.risk_level }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t('screen.single.risk_score.label') }}</td>
                      <td>{{ results.risk_score }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t('screen.single.screened_at.label') }}</td>
                      <td>
                        <DateFormat
                          :date="screeningAt"
                          :showtime="true"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t('screen.single.action.label') }}</td>
                      <td class="pa-2">
                        <v-btn
                          link
                          @click="onSaveResults"
                          color="primary"
                          :loading="saving_results"
                          data-cy="save_screen_result_btn"
                        >
                          <v-icon left>
                            mdi-monitor-screenshot
                          </v-icon>
                          {{ $t('screen.single.buttons.screenshot') }}
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import CountrySelector from '@/components/forms/CountrySelector'
import Vue from 'vue'
import Vuelidate from 'vuelidate'
import { required, minLength } from 'vuelidate/lib/validators'
import DateFormat from '@/components/common/DateFormat'
import html2canvas from 'html2canvas'

Vue.use(Vuelidate)

function initialData () {
  return {
    name: '',
    id: '',
    nationality: '',
    dob: null
  }
}

export default {
  name: 'Template',
  components: {
    CountrySelector,
    DateFormat
  },
  data: () => {
    return {
      // records: [ {id: 123, hitDate: 123 } ]
      form: initialData(),
      results: false,
      loading: false,
      // save results
      saving_results: false,
      screeningAt: null
    }
  },
  computed: {
    // url () {
    //   return 'http://52.187.115.139/AMLWSVIEW2/iSemWLResults.aspx?'
    // }
  },
  validations () {
    return {
      form: {
        name: {
          required,
          minLength: minLength(2)
        }
      }
    }
  },
  methods: {
    reset () {
      Object.assign(this.form, initialData())
      this.$v.$reset()
    },
    onSubmit () {
      this.$v.form.$touch()

      if (!this.$v.form.$error) {
        this.loading = true
        this.$store.dispatch('scan/singleScreening', this.form)
          .then((results) => {
            this.loading = false
            this.reset()
            if (results.status === 200) {
              this.results = results.data
            } else {
              this.$notify({
                type: 'error',
                title: 'Fail to Scan',
                text: ''
              })
            }
          }).catch((error) => {
            console.log(error)
            this.loading = false
          }).finally(() => {
            this.screeningAt = Date.now()
          })
      }
    },
    onSaveResults () {
      this.saving_results = true
      document.querySelector('#app').classList.add('html2canvas')
      html2canvas(document.querySelector('#app'), {
        scrollX: -window.scrollX,
        scrollY: -window.scrollY,
        windowWidth: document.documentElement.offsetWidth,
        windowHeight: document.documentElement.offsetHeight
      }).then(function (canvas) {
        var link = document.createElement('a')
        link.download = 'hit_results.png'
        link.href = canvas.toDataURL('image/png')
        link.click()
      })
        .catch(() => {

        })
        .finally(() => {
          document.querySelector('#app').classList.remove('html2canvas')
          this.saving_results = false
        })
    }
  },
  created: () => {

  },
  mounted: function () {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
